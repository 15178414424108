import ClientJS from 'clientjs';
import axios from 'axios';

new ClientJS();
const windowClient = new window.ClientJS();

const BrowserVersionCheckController = ({ setBrowserList, setBrowserModalVisible }) => {
  const clientVersion = windowClient.getBrowserMajorVersion();

  const getBrowserData = async () => {
    const {
      data: { data }
    } = await axios.get('/api/browser/getLatestVersion');

    _checkBrowser(data);
  };

  function _compareVersion(clientVersion, serverVersion) {
    if (serverVersion - clientVersion > 5) setBrowserModalVisible(true);
  }

  function _checkBrowser(serverData) {
    if (windowClient.isChrome()) {
      setBrowserList({ chrome: true, edge: true });
      _compareVersion(clientVersion, serverData.chrome);
    } else if (windowClient.isFirefox()) {
      setBrowserList({ firefox: true });
      _compareVersion(clientVersion, serverData.firefox);
    } else if (windowClient.isOpera()) {
      setBrowserList({ opera: true });
      _compareVersion(clientVersion, serverData.opera);
    } else if (windowClient.isSafari()) {
      setBrowserList({ safari: true });
      _compareVersion(clientVersion, serverData.safari);
    } else {
      setBrowserList({ chrome: true, edge: true, firefox: true, opera: true, safari: true });
      setBrowserModalVisible(true);
    }
  }
  return { getBrowserData };
};

export default BrowserVersionCheckController;
