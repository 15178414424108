import React from 'react';
import { Space } from 'antd';
import { BrowserCard } from '@core_modules/BrowserVersionCheck/components';

/**ICON COMPONENTS */
import ChromeLogo from '@core_assets/images/chrome_icon.png';
import EdgeLogo from '@core_assets/images/edge_icon.png';
import FirefoxLogo from '@core_assets/images/firefox_icon.png';
import OperaLogo from '@core_assets/images/opera_icon.png';
import SafariLogo from '@core_assets/images/safari_icon.png';

function BrowserDownloadList({ browserList }) {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}
    >
      <Space size="middle">
        {browserList.chrome === true && (
          <BrowserCard
            browserName="Chrome"
            browserIcon={ChromeLogo}
            browserLink="https://www.google.com/intl/en_us/chrome/"
          />
        )}
        {browserList.edge === true && (
          <BrowserCard
            browserName="Edge"
            browserIcon={EdgeLogo}
            browserLink="https://www.microsoft.com/en-us/edge"
          />
        )}
        {browserList.firefox === true && (
          <BrowserCard
            browserName="Firefox"
            browserIcon={FirefoxLogo}
            browserLink="https://www.mozilla.org/en-US/firefox/new/"
          />
        )}
        {browserList.opera === true && (
          <BrowserCard
            browserName="Opera"
            browserIcon={OperaLogo}
            browserLink="https://www.opera.com/"
          />
        )}
        {browserList.safari === true && (
          <BrowserCard
            browserName="Safari"
            browserIcon={SafariLogo}
            browserLink="https://support.apple.com/downloads/safari"
          />
        )}
      </Space>
    </div>
  );
}

export default BrowserDownloadList;
