import { cast, types, flow } from 'mobx-state-tree';
import axios from 'axios';

import UserTypesModel from '@core_modules/UserManagement/model/UserTypesModel';

const API_PATH = '/api/users';

const UserManagementUtilities = types
  .model('UserManagementUtilities', {
    showAddUserModal: types.optional(types.boolean, false),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), ['first_name', 'last_name', 'email']),
    userTypes: types.optional(types.array(UserTypesModel), []),
    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.string, ''),
    cities: types.optional(types.array(types.string), []),
    selectedRowsKeys: types.optional(types.array(types.string), []),
    currentPage: types.optional(types.integer, 1),
    isDownloading: types.optional(types.boolean, false),
    isDownloadingPdf: types.optional(types.boolean, false)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setToggleShowAddOrUpdateUserModal(isUpdate = false) {
      self.isUpdate = isUpdate ? true : false;
      self.showAddOrUpdateUserModal = !self.showAddOrUpdateUserModal;
    },

    setSelectedRowsKeys(selectedRowsKeys) {
      self.selectedRowsKeys = cast(selectedRowsKeys);
    },

    setChangePage(currentPage) {
      self.currentPage = currentPage;
    },

    setUpdateId(id) {
      self.updateId = id;
    },

    setSearch(searchValue) {
      self.search = searchValue;
    },
    setIsDownloading(isDownloading) {
      self.isDownloading = isDownloading;
    },
    setIsDownloadingPdf(isDownloadingPdf) {
      self.isDownloadingPdf = isDownloadingPdf;
    },

    FETCH_USER_TYPES: flow(function* () {
      const { data } = yield axios.get(`${API_PATH}/types`);
      self.userTypes = cast(data);
    })
  }));

export default UserManagementUtilities;
