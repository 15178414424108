import { message } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const TokenCheckerController = ({ store, form, t, setIsDeleteVisible, history }) => {
  const getTokenCheckers = async () => {
    const page = 1;

    const search = store.TokenCheckerManagementUtilities.search;
    const props = store.TokenCheckerManagementUtilities.props;
    const size = store.TokenCheckerManagementUtilities.chosenTableSize;
    let params = {
      page,
      size,
      search,
      props,
      user_type: store.login.type,
      user_id: store.login.id
    };

    await store.TokenChecker.LIST(params);
  };

  const onChangePage = async (page) => {
    const search = store.TokenCheckerManagementUtilities.search;
    const props = store.TokenCheckerManagementUtilities.props;
    const size = store.TokenCheckerManagementUtilities.chosenTableSize;

    let params = {
      size,
      page,
      search,
      props,
      user_type: store.login.type,
      user_id: store.login.id
    };

    await store.TokenChecker.LIST(params);
  };

  const handleSizeChange = (size) => {
    store.TokenCheckerManagementUtilities.setChosenTableSize(size);
  };

  const handleToggleAddOrUpdateShowTokenCheckerModal = (
    isUpdate = false,
    TokenCheckerData = null
  ) => {
    form.resetFields();
    if (isUpdate) {
      TokenCheckerData = store.TokenChecker.state.find((c) => c.id === TokenCheckerData?.id);
      form.setFieldsValue(TokenCheckerData);
      store.TokenCheckerManagementUtilities.setUpdateId(TokenCheckerData?.id);
    }

    store.TokenCheckerManagementUtilities.setToggleShowAddOrUpdateTokenCheckerModal(isUpdate);
  };
  const handleChangeForm = (values) => {};

  const addUserId = (values) => {
    values.user_id = store.login.id;
    return values;
  };

  const handleDeleteMultiple = async () => {
    const selectedRowIds = store.TokenCheckerManagementUtilities.selectedRowsKeys;
    const currentPage = store.TokenCheckerManagementUtilities.currentPage;
    await store.TokenChecker.DELETE(selectedRowIds);
    setIsDeleteVisible(false);
    await onChangePage(currentPage);
  };

  const handleUpdateOrCreateTokenChecker = async (values, isUpdate, isSelf = false) => {
    values = addUserId(values);

    message.loading({
      content: isUpdate ? t('Updating TokenChecker') : t('Creating TokenChecker'),
      key: 'creatingTokenChecker'
    });

    const params = isUpdate
      ? [store.TokenCheckerManagementUtilities.updateId, values] // If updating self use id in login store
      : [values];

    let [successMessage, error] = await store.TokenChecker[isUpdate ? 'UPDATE' : 'CREATE'](
      ...params
    );

    if (!isUpdate) {
      store.users.setTotal(store.users.total + 1);
    }

    let success = await _showresultMessage(error, {
      successMessage: t(successMessage.message) // isUpdate ? t('TokenChecker Updated') :
    });

    if (success) {
      if (!isSelf) {
        form.resetFields();
        store.TokenCheckerManagementUtilities.setToggleShowAddOrUpdateTokenCheckerModal();
      }
    }
  };

  const handleFetchTokenCheckerTypes = async () => {
    await store.TokenCheckerManagementUtilities.FETCH_TokenChecker_TYPES();
  };

  const deleteFile = async () => {
    store.TokenChecker.deleteFile();
  };
  const handleFetchFilterData = async () => {
    const page = 0;
    const columns = store.UserManagementUtilities.columns;

    let params = { page, columns };

    await store.users.LIST(params);
  };

  const onChangeFilter = async (pagination, filters, sorter, extra) => {
    const page = pagination.current;
    const props = store.UserManagementUtilities.props;
    const size = store.TokenCheckerManagementUtilities.chosenTableSize;
    let isFilter;
    let isSort = false;
    let selectedColumn = '';
    let selectedSorter = '';
    const filteredFilters = {};
    if (sorter.order !== undefined) {
      isSort = true;
      selectedColumn = sorter.column.dataIndex;
      selectedSorter = sorter.order;
    } else {
      isSort = false;
    }
    if (Object.keys(filters).length !== 0) {
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== null) {
          filteredFilters[key] = value;
        }
      });
      if (Object.keys(filteredFilters).length !== 0) {
        isFilter = true;
        filters = JSON.stringify(filteredFilters);
      } else {
        isFilter = false;
        filters = null;
      }
    } else {
      isFilter = false;
    }

    let params;

    switch (extra.action) {
      case 'filter':
        params = {
          page: 1,
          filter: isFilter,
          filters,
          isSort,
          selectedSorter,
          selectedColumn,
          size
        };
        await store.users.LIST(params);
        break;

      case 'paginate':
        if (Object.keys(filteredFilters).length === 0) {
          params = {
            page,
            props,
            isSort,
            selectedSorter,
            selectedColumn,
            size
          };
        } else {
          params = {
            page,
            filter: isFilter,
            filters,
            isSort,
            selectedSorter,
            selectedColumn,
            size
          };
        }
        await store.users.LIST(params);
        break;

      case 'sort':
        if (Object.keys(filteredFilters).length === 0) {
          params = {
            page,
            props,
            isSort,
            selectedSorter,
            selectedColumn,
            size
          };
        } else {
          params = {
            page,
            filter: isFilter,
            filters,
            isSort,
            selectedSorter,
            selectedColumn,
            size
          };
        }

        await store.users.LIST(params);
        break;

      default:
        break;
    }
    // }
  };
  // Helper function to create the params object
  function _createParamsObject(
    page,
    isFilter,
    filters,
    isSort,
    selectedSorter,
    selectedColumn,
    props
  ) {
    const params = { page, filter: isFilter, filters, isSort, selectedSorter, selectedColumn };
    if (Object.keys(filters).length === 0) {
      delete params.filters; // Remove filters property if it's empty
    }
    if (!isFilter) {
      delete params.filter; // Remove filter property if isFilter is false
    }
    if (!isSort) {
      delete params.isSort; // Remove isSort property if it's false
      delete params.selectedSorter; // Remove selectedSorter property if it's empty
      delete params.selectedColumn; // Remove selectedColumn property if it's empty
    }
    if (!props) {
      delete params.props; // Remove props property if it's false
    }
    return params;
  }

  const getFilterData = (
    data,
    filter,
    property = [],
    booleanReplacer = { true: 'Active', false: 'Inactive' }
  ) => {
    let text = '';
    if (property.length > 0) {
      property.forEach((prop, idx) => {
        if (idx === 0) {
          text = `item['${prop}']`;
        } else {
          text = `${text}['${prop}']`;
        }
      });
    } else {
      text = `item['${filter}']`;
    }

    const seenValues = new Set();
    return data.reduce((result, item) => {
      const textLabel = typeof eval(text) === 'boolean' ? booleanReplacer[eval(text)] : eval(text);
      const value = item[filter];

      if (!seenValues.has(textLabel)) {
        seenValues.add(textLabel);
        result.push({
          text: textLabel,
          value
        });
      }
      return result;
    }, []);
  };

  const handleExportData = async () => {
    store.TokenCheckerManagementUtilities.setIsDownloading(true);

    let response = await store.TokenChecker.exportData();

    if (response.data) {
      response.data = _changeCSVSeparator(response.data);

      const url = window.URL.createObjectURL(
        new Blob(['\ufeff', response.data], {
          type: response.headers['content-type'] + ';text/csv;charset=utf-8'
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `export_file.csv`); //or any other extension
      document.body.appendChild(link);
      link.click();
      await deleteFile();

      store.TokenCheckerManagementUtilities.setIsDownloading(false);
    }
  };

  const handleTokenCheckerSearch = async (search) => {
    search = search.trim();
    const props = store.TokenCheckerManagementUtilities.props;
    let params = { search, props, user_type: store.login.type, user_id: store.login.id };

    store.TokenCheckerManagementUtilities.setSearch(search);
    await store.TokenChecker.LIST(params);
  };

  const handleChangeTokenCheckerStatus = async (params) => {
    let { TokenCheckerData, active } = params;

    let [successMessage, error] = await store.TokenChecker.UPDATE(TokenCheckerData.id, {
      active
    });

    await _showresultMessage(error, {
      successMessage: successMessage.message
    });
  };

  const handleSendEmailToTokenChecker = async (TokenCheckerData) => {
    message.loading({
      content: t('Sending Email'),
      key: 'creatingTokenChecker'
    });

    let [successResponse, error] = await store.TokenChecker.SEND_EMAIL({
      id: TokenCheckerData.id,
      email: TokenCheckerData.email
    });

    await _showresultMessage(error, {
      successMessage: t(successResponse.message)
    });
  };

  const handleSetSelectedRowKeys = async (setSelectedRowsKeys) => {
    store.TokenCheckerManagementUtilities.setSelectedRowsKeys(setSelectedRowsKeys);
  };

  const getDropdown = async (type) => {
    let { data } = await store.dropdownSettings.getDropdownSetting(type);
    store.TokenChecker.singleState.setDropdownList(type, data.data);
  };

  const handleDeleteTokenChecker = async () => {
    let data = form.getFieldValue();
    await store.TokenChecker.DELETE(data.id);
    getTokenCheckers();

    store.TokenCheckerManagementUtilities.setToggleShowAddOrUpdateTokenCheckerModal();
    form.resetFields();
  };

  // let idleTimer;
  // //testing

  // const idleDuration = 60 * 1000;

  // const resetIdleTimer = () => {
  //   clearTimeout(idleTimer);
  //   idleTimer = setTimeout(() => {
  //     store.login.logout();
  //     // window.location.reload(true);
  //   }, idleDuration);
  // };

  // const handleUserActivity = () => {
  //   resetIdleTimer();
  // };

  let idleTimer;
  const idleDuration = 60 * 60 * 1000;

  const resetIdleTimer = () => {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(() => {
      store.TokenCheckerManagementUtilities.setShowTokenExpirationRefresh(true);
    }, idleDuration);
  };
  const handleUserActivity = () => {
    resetIdleTimer();
  };
  // // const handleLogoutUser = () => {
  //   let currentTime;

  //   // let tokenExpiredTime = 30;
  //   // const tokenExpirationTime = dayjs().add(tokenExpiredTime, 'second').unix();
  //   // localStorage.setItem('tokenExpirationTime', tokenExpirationTime);
  //   const interval = setInterval(() => {
  //     currentTime = dayjs().unix();
  //     const storedTokenExpirationTime = parseInt(localStorage.getItem('tokenExpirationTime'));
  //
  //     if (currentTime === storedTokenExpirationTime) {
  //       clearInterval(interval);
  //       store.login.logout();
  //       window.location.reload(true);
  //       // localStorage.removeItem('tokenExpirationTime');
  //     }
  //   }, 1000);
  // };
  const handleLogoutUser = () => {
    const checkTime = () => {
      const currentTime = dayjs().unix();
      const storedTokenExpirationTime = parseInt(localStorage.getItem('tokenExpirationTime'));

      if (currentTime >= storedTokenExpirationTime) {
        store.login.logout();
        window.location.reload(true);
      } else if (currentTime < storedTokenExpirationTime) {
        // If current time is less than the token expiration time, continue checking
        setTimeout(checkTime, 1000); // Check again after 1 second
      }
    };

    // Start checking
    checkTime();
  };
  const handleShowTokenExpirationTime = async () => {
    const tokenExpirationTime = dayjs().add(45, 'minute').unix();
    const interval = setInterval(() => {
      const currentTime = dayjs().unix();
      if (currentTime === tokenExpirationTime + 3) {
        store.TokenCheckerManagementUtilities.setShowTokenExpiration(true);
        clearInterval(interval);
      }
    }, 1000);
  };
  const handleGetDateFormat = () => {
    return store.TokenCheckerManagementUtilities.getDateFormat(store.globalState.language);
  };

  async function _showresultMessage(error, { successMessage }) {
    return new Promise((resolve, reject) => {
      if (error) {
        message.error({
          content: t(error.response.data.error.message),
          key: 'creatingTokenChecker'
        });

        reject(false);
      } else {
        message.success({ content: successMessage, key: 'creatingTokenChecker' });
        resolve(true);
      }
    });
  }

  function _changeCSVSeparator(data) {
    let locale = store.globalState.language;

    switch (locale) {
      case 'de':
        return data.replace(/,/g, ';');
      default:
        return data;
    }
  }

  return {
    getTokenCheckers,
    onChangePage,
    handleSizeChange,
    handleToggleAddOrUpdateShowTokenCheckerModal,
    handleChangeForm,
    handleUpdateOrCreateTokenChecker,
    handleFetchTokenCheckerTypes,
    handleTokenCheckerSearch,
    handleChangeTokenCheckerStatus,
    handleSendEmailToTokenChecker,
    handleDeleteTokenChecker,
    handleDeleteMultiple,
    handleSetSelectedRowKeys,
    handleExportData,
    handleGetDateFormat,
    getDropdown,
    handleFetchFilterData,
    getFilterData,
    onChangeFilter,

    handleShowTokenExpirationTime,
    handleUserActivity,
    handleLogoutUser
  };
};

export default TokenCheckerController;
