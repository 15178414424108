import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { inject, observer } from 'mobx-react';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';
import TokenExpirationModal from '../components/TokenExpirationModal';

function TokenChecker({ store }) {
  const { t } = useTranslation('common');
  // const [form] = Form.useForm();

  const [isDeleteVisible, setIsDeleteVisible] = useState(false);

  return (
    <div>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <TokenExpirationModal />
        {/* <TokenCheckerTable form={form} setIsDeleteVisible={setIsDeleteVisible} /> */}
      </Suspense>
    </div>
  );
}

export default inject('store')(observer(TokenChecker));
