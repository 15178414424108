import { message } from 'antd';

const DropdownSettingsController = ({ setDropdownSettingsVisible, store, t }) => {
  const handleToggleDropdownSettingsModal = () => {
    setDropdownSettingsVisible((prev) => !prev);
  };

  const handleDownload = async () => {
    store.dropdownSettings.setIsDownloading(true);

    let response = await store.dropdownSettings.generateCSV();

    response.data = _changeCSVSeparator(response.data);
    console.log(response.data);
    //if (response.data) {
    //let response = await store.dropdownSettings.getCSV();
    const url = window.URL.createObjectURL(
      new Blob(['\ufeff', response.data], {
        type: response.headers['content-type'] + ';text/csv;charset=utf-8'
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `dropdown_csv.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();

    await deleteFile();

    store.dropdownSettings.setIsDownloading(false);
    // } else {
    //   console.log(response, 'Tetse');
    // }
  };

  const deleteFile = async () => {
    store.dropdownSettings.deleteFile();
  };

  const handleUploadingCSV = (info) => {
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      let { success, msg } = info.file.response;
      if (success) {
        message.success({ content: t(msg) });
      } else {
        message.error({ content: t(msg), duration: 5 });
      }
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  function _changeCSVSeparator(data) {
    let locale = store.globalState.language;

    switch (locale) {
      case 'de':
        return data.replace(/,/g, ';');
      default:
        return data;
    }
  }

  return { handleToggleDropdownSettingsModal, handleDownload, handleUploadingCSV };
};

export default DropdownSettingsController;
