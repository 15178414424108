import { types, cast } from 'mobx-state-tree';
import Dropdownmodel from './DropdownModel';

const CategoryParentModel = types
  .model('CategoryParentModel', {
    id: types.optional(types.integer, 0),
    key: types.optional(types.integer, 0),
    category_type_id: types.optional(types.maybeNull(types.integer), 0),
    name: types.optional(types.string, '')
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default CategoryParentModel;
