import { types, flow } from 'mobx-state-tree';
import UsersModel from './UsersModel';
import axios from 'axios';
import ApplyRootCrud from '@core_state_management/Helpers/RootCrud';

const API_PATH = '/api/users';
const API_EXPORT_PATH = '/api/users/export_csv';
const API_PDF_PATH = '/api/pdf';
export default types
  .model('UsersState', {
    state: types.optional(types.array(UsersModel), []),
    single: types.optional(UsersModel, {}),
    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false),
    updatedAt: types.optional(types.string, '')
  })
  .actions((self) => ({
    ...ApplyRootCrud(API_PATH, self),

    SEND_EMAIL: flow(function* (payload, lang) {
      try {
        let response = yield axios.post(`${API_PATH}/send_email`, { payload, lang });
        return [{ message: response.data.data.message }, null];
      } catch (error) {
        return [null, error];
      }
    }),

    setTotal: (total) => {
      self.total = total;
    },
    exportData: flow(function* () {
      return yield axios.get(API_EXPORT_PATH);
    }),
    pdfData: flow(function* (contentHtml) {
      return yield axios.get(API_PDF_PATH, { responseType: 'blob' });
    }),

    getCSV: flow(function* () {
      return yield axios({
        url: '/public/export/export_file.csv', //your url
        method: 'GET'
        // responseType: 'blob' // important
      });
    }),

    deleteFile: flow(function* () {
      yield axios.delete('/api/users/delete_csv');
    })
  }))
  .views((self) => ({}));
