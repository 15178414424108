import { types, cast, flow } from 'mobx-state-tree';
import Dropdownmodel from './DropdownModel';

const TokenCheckerModel = types
  .model('TokenCheckerModel', {
    id: types.optional(types.integer, 0),
    key: types.optional(types.integer, 0),
    dropdown_input_list: types.optional(types.array(Dropdownmodel), [])
  })
  .views((self) => ({}))
  .actions((self) => ({
    setDropdownList: flow(function* (type, list) {
      self[`${type}_list`] = cast(list);
    })
  }));

export default TokenCheckerModel;
