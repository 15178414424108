import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

/**CORE COMPONENTS */
import { ExactTitle } from '@core_common/components';

function Description() {
  const { t } = useTranslation('common');
  const { Paragraph } = Typography;

  return (
    <div>
      <ExactTitle level={3} text={t('BrowserVersion_#Your browser seems to be outdated...')} />
      <Paragraph>{t(`BrowserVersion_#Paragraph1`)}</Paragraph>
      <Paragraph>{t(`BrowserVersion_#Paragraph2`)}</Paragraph>
    </div>
  );
}

export default Description;
