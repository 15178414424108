import { types, cast } from 'mobx-state-tree';
import Dropdownmodel from './DropdownModel';
import CategoryParentModel from './CategoryParentModel';

const CategoryTypesModel = types
  .model('CategoryTypesModel', {
    id: types.optional(types.integer, 0),
    key: types.optional(types.integer, 0),
    category_type_id: types.optional(types.maybeNull(types.integer), 0),
    name: types.optional(types.string, ''),
    CategoryParent: types.optional(types.maybeNull(CategoryParentModel, {}))
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default CategoryTypesModel;
