import { types } from 'mobx-state-tree';

const Dropdownmodel = types
  .model('DropdownModel', {
    id: types.optional(types.integer, 0),
    dropdown_type: types.optional(types.string, ''),
    entry_us: types.optional(types.string, ''),
    entry_de: types.optional(types.string, ''),
    value: types.optional(types.maybeNull(types.string), ''),
    status: types.optional(types.boolean, true)
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default Dropdownmodel;
