import { types, cast, flow } from 'mobx-state-tree';

const TokenCheckerManagementUtilities = types
  .model('TokenCheckerManagementUtilities', {
    showAddTokenCheckerModal: types.optional(types.boolean, false),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), []),
    columns: types.optional(types.array(types.string), ['id']),
    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.integer, 0),
    selectedRowsKeys: types.optional(types.array(types.string), []),
    isDownloading: types.optional(types.boolean, false),
    chosenTableSize: types.optional(types.integer, 15),
    tokenExpiration: types.optional(types.boolean, false),
    tokenExpirationRefresh: types.optional(types.boolean, false),
    isLogout: types.optional(types.boolean, false)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setToggleShowAddOrUpdateTokenCheckerModal(isUpdate = false) {
      self.isUpdate = isUpdate ? true : false;
      self.showAddOrUpdateTokenCheckerModal = !self.showAddOrUpdateTokenCheckerModal;
    },
    setShowTokenExpiration(tokenExpiration = false) {
      self.tokenExpiration = tokenExpiration ? true : false;
    },
    setShowTokenExpirationRefresh(tokenExpirationRefresh = false) {
      self.tokenExpirationRefresh = tokenExpirationRefresh ? true : false;
    },
    setIsLogout(isLogout = false) {
      self.isLogout = isLogout ? true : false;
    },

    setChosenTableSize: (val) => {
      self.chosenTableSize = val;
    },
    setTokenExpirationTime: (time) => {
      self.tokenExpirationTime = time;
    },

    setSelectedRowsKeys(selectedRowsKeys) {
      self.selectedRowsKeys = cast(selectedRowsKeys);
    },

    setIsDownloading(isDownloading) {
      self.isDownloading = isDownloading;
    },

    setSearch(searchValue) {
      self.search = searchValue;
    },
    setUpdateId(id) {
      self.updateId = id;
    },
    // GET_TOKEN_EXPIRATION: flow(function* () {
    //   const { data } = yield axios.get(`/api/token_checker/token`);
    //   self.tokenExpirationTime = data.expirationToken;
    // }),

    getDateFormat(locale) {
      switch (locale) {
        case 'de':
          return 'DD.MM.YYYY';
        default:
          return 'DD-MM-YYYY';
      }
    }
  }));

export default TokenCheckerManagementUtilities;
