import React, { Suspense, useState } from 'react';

import { Form } from 'antd';
import { inject, observer } from 'mobx-react';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';
import { IssuesTable, IssuesHeader } from '@app_modules/Issues/components';

function Issues({ store }) {
  const [form] = Form.useForm();

  const [isDeleteVisible, setIsDeleteVisible] = useState(false);

  return (
    <div>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <IssuesHeader
          form={form}
          isDeleteVisible={isDeleteVisible}
          setIsDeleteVisible={setIsDeleteVisible}
        />
      </Suspense>

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <IssuesTable form={form} setIsDeleteVisible={setIsDeleteVisible} />
      </Suspense>
    </div>
  );
}

export default inject('store')(observer(Issues));
