import { ExactText } from '@core_common/components';
import Routes from '@core_routing/Routes';
import { Alert, Button, Form, Modal, Space, message } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TokenCheckerController } from '../controller';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import './styles.css';
function TokenExpirationModal({ store }) {
  const { t } = useTranslation('common');
  const history = useHistory();
  const { handleUserActivity, handleShowTokenExpirationTime, handleLogoutUser } =
    TokenCheckerController({
      store,
      t,
      history
    });
  // const expirationTime = dayjs().add(20, 'second').unix();
  const expirationTime = dayjs().add(19, 'hour').add(59, 'minute').add(59, 'second').unix();
  useEffect(() => {
    if (store.login.isLoggedIn) {
      //AUTO LOGOUT INACTIVE USERS
      handleUserActivity();
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keypress', handleUserActivity);
      localStorage.setItem('tokenExpirationTime', expirationTime);
      handleLogoutUser();
    }
  }, [store.login.isLoggedIn]);

  return (
    <>
      {store.login.isLoggedIn && store.TokenCheckerManagementUtilities.tokenExpirationRefresh && (
        <Modal
          footer={null}
          onCancel={() => {
            store.TokenCheckerManagementUtilities.setShowTokenExpirationRefresh(false);
          }}
          visible={store.TokenCheckerManagementUtilities.tokenExpirationRefresh ? true : false}
          title={<ExactText text={t('TokenExpiration_#Inactive Notice')} />}
        >
          <div>
            {t(
              'TokenExpiration_#You have been inactive for an extended period of time. To prevent your session from expiring, please refresh the page.'
            )}
          </div>
          <div style={{ marginTop: '16px', textAlign: 'right' }}>
            <Button
              onClick={() => {
                window.location.reload(true);
              }}
              type="primary"
            >
              {t('TokenExpiration_#Refresh')}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default inject('store')(observer(TokenExpirationModal));
