import React, { useEffect, useState, Suspense } from 'react';
import { Modal } from 'antd';

/**CORE COMPONENTS */
import { FallBackLoaders } from '@core_common/components';
import { Description, BrowserDownloadList } from '@core_modules/BrowserVersionCheck/components';

import { BrowserVersionCheckController } from '@core_modules/BrowserVersionCheck/controller';

function BrowserVersionCheck({ browserModalVisible, setBrowserModalVisible }) {
  const [browserList, setBrowserList] = useState({
    chrome: false,
    edge: false,
    firefox: false,
    opera: false,
    safari: false
  });
  // Initialize the Browser Version Check Controller
  const { getBrowserData } = BrowserVersionCheckController({
    browserList,
    setBrowserList,
    setBrowserModalVisible
  });

  // eslint-disable-next-line
  useEffect(getBrowserData, []);

  return (
    <>
      <Modal visible={browserModalVisible} closable={false} footer={null} className="exact-modal">
        <div className="m-2">
          <Suspense fallback={FallBackLoaders.EmptyLoader}>
            <Description />
            <BrowserDownloadList browserList={browserList} />
          </Suspense>
        </div>
      </Modal>
    </>
  );
}

export default BrowserVersionCheck;
