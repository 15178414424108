import { types, flow, cast } from 'mobx-state-tree';
import IssuesModel from './IssuesModel';
import ApplyRootCrud from '@core_state_management/Helpers/RootCrud';
import axios from 'axios';

const API_PATH = '/api/issues';
const API_EXPORT_PATH = '/api/issues/export_csv';
const API_FIXED_PATH = '/api/issues/get_fixed?fixed=';
export default types
  .model('IssuesState', {
    state: types.optional(types.array(IssuesModel), []),
    single: types.optional(IssuesModel, {}),
    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false)
  })
  .actions((self) => ({
    ...ApplyRootCrud(API_PATH, self),

    setTotal: (total) => {
      self.total = total;
    },

    exportData: flow(function* () {
      return yield axios.get(API_EXPORT_PATH);
    }),

    getCSV: flow(function* () {
      return yield axios({
        url: '/public/export/export_file.csv', //your url
        method: 'GET',
        responseType: 'blob' // important
      });
    }),

    deleteFile: flow(function* () {
      yield axios.delete('/api/feedback/delete_csv');
    })
    // getFixed: flow(function* (fixed) {
    //   try {
    //     let {
    //       data: { data }
    //     } = yield axios.get(`${API_FIXED_PATH}${fixed}`);
    //     console.log(data);
    //     self.state = cast(data);

    //     return data;
    //   } catch (error) {
    //     return [null, error];
    //   }
    // })
  }))
  .views((self) => ({}));
