import React from 'react';
import { Card, Image } from 'antd';

function BrowserCard({ browserName, browserIcon, browserLink }) {
  const { Meta } = Card;
  return (
    <Card
      style={{ width: 125 }}
      cover={
        <a href={browserLink} rel="noreferrer" target="_blank">
          <Image preview={false} src={browserIcon} alt={browserName} />
        </a>
      }
    >
      <Meta title={browserName} />
    </Card>
  );
}

export default BrowserCard;
