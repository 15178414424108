import React from 'react';
import { UnorderedListOutlined } from '@ant-design/icons';
import Routes from '@app_routing/Routes';

const AppMenus = [
  {
    key: '1',
    icon: <UnorderedListOutlined />,
    route: Routes.ISSUES_ROUTE,
    menuName: 'Issue_#Issues'
  }
];

export default AppMenus;
