import axios from 'axios';
import { flow } from 'mobx';
import { types } from 'mobx-state-tree';

export default types
  .model('DropdownSettingModel', {
    isDownloading: types.optional(types.boolean, false)
  })

  .views((self) => ({}))
  .actions((self) => ({
    setIsDownloading(isDownloading) {
      self.isDownloading = isDownloading;
    },

    getDropdownSetting: flow(function* (type) {
      return yield axios.get('/api/dropdown_settings', {
        params: {
          dropdown_type: type
        }
      });
    }),

    generateCSV: flow(function* () {
      return yield axios.get('/api/dropdown_settings/generate_csv');
    }),

    getCSV: flow(function* () {
      return yield axios({
        url: '/public/dropdown/dropdown_csv.csv', //your url
        method: 'GET',
        responseType: 'blob' // important
      });
    }),

    deleteFile: flow(function* () {
      yield axios.delete('/api/dropdown_settings/delete_csv');
    })
  }));
